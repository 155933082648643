import React from 'react';

import { Link, NavLink, useLocation } from 'react-router-dom';

function Header() {
  const { pathname } = useLocation();

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div id="header" className={`header header-${pathname === '/' ? 'fix' : 'bg'}`}>
      <div className="container">
        <div className="header__row">
          <div className="header__logo">
            <Link to="/" onClick={closeMenu}>
              <img src="/images/logo.png" alt="" />
            </Link>
          </div>
          <div className="header__nav">
            <button type="button" className="btn-open-nav" onClick={toggleMenu}>
              <span />
              <span />
              <span />
            </button>
            <ul className={isMenuOpen === true ? 'open' : ''}>
              <li>
                <NavLink to="/international" onClick={closeMenu}>
                  International
                </NavLink>
              </li>
              <li>
                <NavLink to="/asian" onClick={closeMenu}>
                  Asian
                </NavLink>
              </li>
              <li>
                <NavLink to="/ukrainian" onClick={closeMenu}>
                  Ukrainian
                </NavLink>
              </li>
              <li>
                <NavLink to="/latin" onClick={closeMenu}>
                  Latin
                </NavLink>
              </li>
              {/* <li>
                <Link to="/dateasianwoman" className="btn-0" onClick={closeMenu}>
                  Top Offer
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
