import React from 'react';

const stories = [
  {
    names: 'Andrew R.',
    text: `Hi to all! I've read a lot of different opinions, so want to leave my own. Haha. AsiaMe is the best thing that happened in my life this year. I tried many dating sites, including the most famous ones but only here I managed to find a dream girl. I didn't require much from service, I didn't sit idly by, but I searched, talked, and even made mistakes. I was helped by useful tips that really directed my actions in the right way and everything went off. I recommend using paid content, because more is available with it, and "smart search" is just a godsend for those who are shy or lazy.`,
  },
  {
    names: 'Tony R.',
    text: 'You can have a lot of fun chatting here. Ladies are very talkative here, many of them wright you first.',
  },
  {
    names: 'Frank P.',
    text: "The money that you pay for the service worth it. This is definitely a professional and advance dating service that is controlled and managed by smart people. Yes, you have to pay for chats and emails but female profiles get generated for you, so you meet women who match your requirements. This definitely saves time. I am sure if it was free it would be a mess but not a proper dating site. I never got offensive messages like at other dating sites that are free. If you want a high-quality service, pay for it, otherwise, don't complain or recommend a better one.",
  },
  {
    names: 'Den G.',
    text: "This is a nice dating site with beautiful girls who are positive and happy to chat. Even if you don't look for a serious relationship, I'm sure you will find this website very entertaining.",
  },
  {
    names: 'Oliver C.',
    text: `I've been chatting with a girl for 12 days consecutively and I finally scored her phone number and email address. Yes you do have to spend money but if you think she's the one, it's not a problem. I think it's better option than chatting with "nice photos" for months before realizing its a scam site or doesn't work anymore.`,
  },
  {
    names: 'Daniel S.',
    text: "Romance Tale offers a lot of Asian girls. I don't mind Asian girls but it would be more interesting to meet different girls.",
  },
];

function SuccessStories() {
  return (
    <div className="block-4 section">
      <div className="container">
        <div className="title-block">
          <h3>Success stories</h3>
          <p>
            If you’re still in doubt in our dating websites list, we recommend you to read the
            real stories of people who met online
          </p>
        </div>
        <div className="block-4__row">
          {stories.map(({ names, text }, index) => (
            <div key={index} className="block-4__col">
              <div className="block-4__item">
                <div className="block-4__item__content">
                  <div className="block-4__item__content__name">{names}</div>
                  <div className="block-4__item__content__text">{text}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SuccessStories;
