import React from 'react';

function TrackingLink({ offerId, className, children }) {
  const [link, setLink] = React.useState('/go/' + offerId);

  function site() {}

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (localStorage.getItem('tid') !== null) {
        setLink((link) => `${link}?tid=${localStorage.getItem('tid')}`);

        clearInterval(intervalId);
      }
    }, 50);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <a
      href={link}
      id="site"
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      onClick={site}>
      {children}
    </a>
  );
}

export default TrackingLink;
