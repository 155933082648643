import React from 'react';

import { Parallax } from 'react-parallax';
import { Switch, Route } from 'react-router-dom';

import Banner from '../../components/Banner';
// import TopDatingMin from '../../components/TopDatingMin';
import TrackingLink from '../../components/TrackingLink';

import Asian from './asian';
import Ukrainian from './ukrainian';
import Latin from './latin';
import International from './international';
import SmallDisclosure from '../../components/SmallDisclosure';

function Categories({ location }) {
  const category = location.pathname.slice(1);

  const data = {
    asian: {
      offer: 'romanceast',
      title: 'LEADING ASIAN DATING SITES 2023',
    },
    ukrainian: {
      offer: 'theluckydate',
      title: 'LEADING UKRAINIAN DATING SITES 2023',
    },
    latin: {
      offer: 'latamjoy',
      title: 'LEADING LATIN DATING SITES 2023',
    },
    international: {
      offer: 'theluckydate',
      title: 'LEADING INTERNATIONAL DATING SITES 2023',
    },
  };

  const { offer, title } = data[category];

  return (
    <div className="main section-bottom">
      <Parallax strength={300} bgImage={`/images/backgrounds/${category}.jpg`}>
        <div className="bg-block">
          <div className="bg-block__text container">
            <h1>{title}</h1>
            <TrackingLink key={category} offerId={offer} className="btn-0 btn-banner">
              Find a lady
            </TrackingLink>
          </div>
        </div>
      </Parallax>
      <div className="container mt-50">
        <div className="title-block">
          <p>
            Through the accumulation and careful analysis of countless site ratings, we have
            meticulously constructed this ranking to serve your convenience!
          </p>
        </div>
        <p className="table-sight">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 20 20"
            fill="none">
            <circle cx="10" cy="10" r="10" fill="#333333" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15 7.75001L8.847 13.75L8.84599 13.7491V13.75L5 10L6.53799 8.49999L8.84599 10.75L13.462 6.25L15 7.75001Z"
              fill="white"
            />
          </svg>
          Updated for August 2023
        </p>
        <SmallDisclosure />
        <div className="main__row">
          <div className="content">
            <div className="content__text">
              <Switch>
                <Route exact path="/ukrainian" component={Ukrainian} />
                <Route exact path="/asian" component={Asian} />
                <Route exact path="/latin" component={Latin} />
                <Route exact path="/international" component={International} />
              </Switch>
            </div>
          </div>
          <div className="right-col right-col-margin">
            <Banner />
            {/* <TopDatingMin /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Categories;
