const offers = [
  {
    id: 'theluckydate',
    name: 'TheLuckyDate',
    image: 'theluckydate.svg',
    rating: 5,
    score: 9.9,
    text: [
      'You can join for free',
      'Multiple Search Filters',
      'Send virtual flowers and gifts',
    ],
    review: '/theluckydate',
    isFavorite: false,
  },
  {
    id: 'chattyspace',
    name: 'ChattySpace',
    image: 'chattyspace.svg',
    rating: 5,
    score: 9.8,
    text: [
      'Focus on interesting acquaintances',
      'Large & Varied User Base',
      'You can join for free',
    ],
    review: '/chattyspace',
    isFavorite: false,
  },
  {
    id: 'romanceast',
    name: 'Romanceast',
    image: 'romanceast.svg',
    rating: 5,
    score: 9.7,
    text: ['Send virtual flowers and gifts', 'Many communication tools', 'Great search tool'],
    review: '/romanceast',
    isFavorite: false,
  },
  {
    id: 'latamjoy',
    name: 'LatamJoy',
    image: 'latamjoy.svg',
    rating: 5,
    score: 9.5,
    text: [
      'Beautiful Colombian Ladies',
      'Free and simple registration',
      'Zero tolerance to scam',
    ],
    review: '/latamjoy',
    isFavorite: false,
  },
  {
    id: 'orchidromance',
    name: 'OrchidRomance',
    image: 'orchidromance.svg',
    rating: 4.5,
    score: 8.7,
    text: [
      'High-Class Asian Women',
      'Search Based on Your Preference',
      'Private Photos Galleries',
    ],
    review: '/orchidromance',
    isFavorite: false,
  },
  {
    id: 'theluckydateasia',
    name: 'TheLuckyDateAsia',
    image: 'theluckydateasia.svg',
    rating: 5,
    score: 9.5,
    text: [
      'Many active Asian women online',
      'Female members based in Asia',
      'Private Photos Galleries',
    ],
    review: '/theluckydateasia',
    isFavorite: false,
  },
  {
    id: 'theluckydatelatam',
    name: 'TheLuckyDateLatam',
    image: 'theluckydatelatam.svg',
    rating: 5,
    score: 9.2,
    text: [
      'Browse profile of your matches for free',
      'Search Based on Your Preference',
      'Convenient search option',
    ],
    review: '/theluckydatelatam',
    isFavorite: false,
  },
  {
    id: 'fortunamor',
    name: 'FortunAmor',
    image: 'fortunamor.svg',
    rating: 5,
    score: 9.1,
    text: [
      'Various communication tools',
      'Free sign-up on the site',
      'Anonymous & Secure Billing',
    ],
    review: '/fortunamor',
    isFavorite: false,
  },
  {
    id: 'chattyspaceasia',
    name: 'ChattySpaceAsia',
    image: 'chattyspaceasia.svg',
    rating: 4.5,
    score: 8.9,
    text: [
      'High-Class Asian Women',
      'Thousands of Asian women',
      'Trending online dating with Asian women',
    ],
    review: '/chattyspaceasia',
    isFavorite: false,
  },
  {
    id: 'chattyspacelatam',
    name: 'ChattySpaceLatam',
    image: 'chattyspacelatam.svg',
    rating: 4.5,
    score: 8.8,
    text: ['Manual Moderation', 'The interface is easy to understand', 'Free registration'],
    review: '/chattyspacelatam',
    isFavorite: false,
  },
  {
    id: 'ladate',
    name: 'LaDate',
    image: 'ladate.svg',
    rating: 4.5,
    score: 8.5,
    text: [
      'Good service with real results',
      'Informative profiles of girls from Latin countries',
      '24-hour customer service',
    ],
    review: '/ladate',
    isFavorite: false,
  },
  {
    id: 'asianmelodies',
    name: 'AsianMelodies',
    image: 'asianmelodies.png',
    rating: 4,
    score: 7.9,
    text: ['Free registration', 'Thousands of users', 'Constant support'],
    review: '/asianmelodies',
    isFavorite: false,
  },
  // {
  //   id: 'latinfeels',
  //   name: 'LatinFeels',
  //   image: 'latinfeels.png',
  //   rating: 5,
  //   score: 9.4,
  //   text: ['Quality latin singles', 'High-quality services', 'Loyal prices'],
  //   review: '/latinfeels',
  //   isFavorite: false,
  // },
  // { 1
  //   id: 'lovefort',
  //   name: 'LoveFort',
  //   image: 'lovefort.png',
  //   rating: 5,
  //   score: 9.2,
  //   text: [
  //     'You can join for free',
  //     'Focus on interesting acquaintances',
  //     'Send virtual flowers and gifts'
  //   ],
  //   review: '/lovefort',
  //   isFavorite: true,
  // },
  // {
  //   id: 'colombialady',
  //   name: 'ColombiaLady',
  //   image: 'colombia-logo.jpg',
  //   rating: 4.5,
  //   score: 8.1,
  //   text: [
  //     'Beautiful Colombian Ladies'
  //     'Multiple Search Filters'
  //     'Large & Varied User Base'
  //   ],
  //   review: '/colombialady',
  //   isFavorite: false,
  // },
  // { 2
  //   id: 'latinbeautydate',
  //   name: 'LatinBeautyDate',
  //   image: 'latinbeautydate-logo.svg',
  //   rating: 4.5,
  //   score: 8.8,
  //   text: [
  //     'You can join for free'
  //     'Many communication tools'
  //     'Send virtual flowers and gifts'
  //   ],
  //   review: '/latinbeautydate',
  //   isFavorite: false,
  // },
  // {
  //   id: 'dateasianwoman',
  //   name: 'DateAsianWoman',
  //   image: 'date-asian-women.png',
  //   rating: 4,
  //   score: 8.5,
  //   text: [
  //     'Free and simple registration'
  //     'Great search tool'
  //     'Many active Asian women online'
  //   ],
  //   review: '/dateasianwoman',
  //   isFavorite: false,
  // },
  // {
  //   id: 'asiabeautydate',
  //   name: 'AsiaBeautyDate',
  //   image: 'asia-beauty-date.svg',
  //   rating: 4,
  //   score: 8.8,
  //   text: [
  //     'Zero tolerance to scam'
  //     'Female members based in Asia'
  //     'Browse profile of your matches for free'
  //   ],
  //   review: '/asiabeautydate',
  //   isFavorite: false,
  // },
  // {
  //   id: 'orchidromance',
  //   name: 'OrchidRomance',
  //   image: 'orchidromance-logo.png',
  //   rating: 4,
  //   score: 9.0,
  //   text: [
  //     'High-Class Asian Women'
  //     'Search Based on Your Preference'
  //     'Private Photos Galleries'
  //   ],
  //   review: '/orchidromance',
  //   isFavorite: false,
  // },
  // {
  //   id: 'dateasianlady',
  //   name: 'DateAsianLady',
  //   image: 'date-asian-lady.png',
  //   rating: 4,
  //   score: 9.2,
  //   text: [
  //     'Convenient search option'
  //     'Thousands of Asian women'
  //     'Various communication tools'
  //   ],
  //   review: '/dateasianlady',
  //   isFavorite: false,
  // },
  // {
  //   id: 'cuteasianwoman',
  //   name: 'CuteAsianWoman',
  //   image: 'cuteasianwoman-logo.png',
  //   rating: 4,
  //   score: 8.5,
  //   text: [
  //     'Large database of Asian ladies',
  //     'Trending online dating with Asian women'
  //     'Free sign-up on the site'
  //   ],
  //   review: '/cuteasianwoman',
  //   isFavorite: false,
  // },
  // {
  //   id: 'hotukrainians',
  //   name: 'HotUkrainians',
  //   image: 'hotukrainians-logo.png',
  //   rating: 4,
  //   score: 9.0,
  //   text: [
  //     'Beautiful Slavic Ladies',
  //     'Mobile/Desktop Compatibility',
  //     'Manual Moderation'
  //     'Anonymous & Secure Billing'
  //   ],
  //   review: '/hotukrainians',
  //   isFavorite: false,
  // },
  // {
  //   id: 'dateukrainiangirl',
  //   name: 'DateUkrainianGirl',
  //   image: 'date-ukrainian-girl.png',
  //   rating: 4,
  //   score: 8.1,
  //   text: ['International online dating', 'Fast Reply', 'The interface is easy to understand' //],
  //   review: '/dateukrainiangirl',
  //   isFavorite: false,
  // },
  // {
  //   id: 'asianmelodies',
  //   name: 'AsianMelodies',
  //   image: 'asianmelodies.png',
  //   rating: 5,
  //   score: 9.3,
  //   text: ['Free registration' 'Thousands of users', 'Constant support'],
  //   review: '/asianmelodies',
  //   isFavorite: false,
  // },
  // {
  //   id: 'amourfactory',
  //   name: 'AmourFactory',
  //   image: 'amourfactory.png',
  //   rating: 4,
  //   score: 7.7,
  //   text: ['Quick and simple signup', 'For people 30+', 'Caring about confidentiality'],
  //   review: '/amourfactory',
  //   isFavorite: false,
  // },
  // {
  //   id: 'amourfeel',
  //   name: 'AmourFeel',
  //   image: 'amourfeel.svg',
  //   rating: 5,
  //   score: 9.5,
  //   text: [
  //     'Very convenient website',
  //     'It is safe, protected by McAfee Secure, DMCA, and SSL encryption',
  //     'Variety of gifts and women profiles',
  //   ],
  //   review: '/amourfeel',
  //   isFavorite: false,
  // },
  // {
  //   id: 'latinwomanlove',
  //   name: 'LatinWomanLove',
  //   image: 'latinwomanlove.png',
  //   rating: 4,
  //   score: 6.5,
  //   text: [
  //     'Good service with real results'
  //     'Informative profiles of girls from Latin countries'
  //     '24-hour customer service'
  //   ],
  //   review: '/latinwomanlove',
  //   isFavorite: false,
  // },
  // {
  //   id: 'talkliv',
  //   name: 'Talkliv',
  //   image: 'talkliv.svg',
  //   rating: 5,
  //   score: 9.5,
  //   text: [
  //     'Convenient website interface. Easy to use from your phone',
  //     'More than 10,000 verified members',
  //     "Users' data and messages aren't kept, making Talkliv totally Anonymous and secure",
  //   ],
  //   review: '/talkliv',
  //   isFavorite: false,
  // },
  // {
  //   id: 'funchatt',
  //   name: 'Funchatt',
  //   image: 'funchatt.svg',
  //   rating: 5,
  //   score: 9.3,
  //   text: [
  //     '700 users join every day',
  //     'There are tips on how to start a conversation or break a deadlock',
  //     'Fast website support',
  //   ],
  //   review: '/funchatt',
  //   isFavorite: false,
  // },
  // {
  //   id: 'placetochat',
  //   name: 'PlaceToChat',
  //   image: 'placetochat.svg',
  //   rating: 4.5,
  //   score: 8.0,
  //   text: [
  //     'Handy for computers, iOS and Android',
  //     'Friendly chat atmosphere',
  //     'Lots of positive user reviews',
  //   ],
  //   review: '/placetochat',
  //   isFavorite: false,
  // },
  // {
  //   id: 'easternhoneys',
  //   name: 'EasternHoneys',
  //   image: 'eastern-honeys.png',
  //   rating: 5,
  //   score: 9.1,
  //   text: [
  //     'Convenient search option',
  //     'Thousands of Asian women',
  //     'Various communication tools',
  //   ],
  //   review: '/easternhoneys',
  //   isFavorite: false,
  // },
  // {
  //   id: 'ukrainiancharm',
  //   name: 'UkrainianCharm',
  //   image: 'ukrainiancharm.png',
  //   rating: 5,
  //   score: 8.1,
  //   text: ['Plenty of Slavic beauties', 'Modern matching tools', 'Quality secured Profiles'],
  //   review: '/ukrainiancharm',
  //   isFavorite: true,
  // },
  // {
  //   id: 'asiame',
  //   name: 'AsiaMe',
  //   image: 'asiame.png',
  //   rating: 4,
  //   score: 8.8,
  //   text: ['Free registration', 'Thousands of users', 'Constant support'],
  //   review: '/asiame',
  //   isFavorite: false,
  // },
  // {
  //   id: 'asianbeautyonline',
  //   name: 'AsianBeautyOnline',
  //   image: 'asian-beauty-online.png',
  //   rating: 4,
  //   score: 8.2,
  //   text: ['Plenty of Asian beauties', 'Modern matching tools', 'Quality secured Profiles'],
  //   review: '/asianbeautyonline',
  //   isFavorite: false,
  // },
];

export default offers;
