import React from 'react';

import InfoIcon from '../icons/Info';
import CloseIcon from '../icons/Close';

import './SmallDisclosure.scss';

function SmallDisclosure(props) {
  const [isOpen, setIsOpen] = React.useState(false);

  const togglePopup = () => setIsOpen((prevIsOpen) => !prevIsOpen);

  const closePopup = () => setIsOpen(false);

  return (
    <div className="SmallDisclosure">
      <div className="SmallDisclosureContainer" {...props}>
        <div className="SmallDisclosureContentBtn">
          <button type="button" className="SmallDisclosureButtonOpen" onClick={togglePopup}>
            <InfoIcon />
            We receive fees from partners | <strong>Advertising Disclosure</strong>
          </button>
          {isOpen === true && (
            <div className="advertising-disclosure__popup">
              <button
                type="button"
                className="advertising-disclosure__popup__close"
                onClick={closePopup}>
                <CloseIcon />
              </button>
              <p>
                This website serves as a helpful resource for useful content and review
                services. It's important to note that companies may appear on the platform as
                part of advertising agreements. Please be aware that the compensation received
                can impact the order, placement, and sometimes even the rating of these
                companies. The way companies are presented here doesn't imply endorsement.
                While we aim for accuracy, our Terms of Use outline certain exceptions. The
                information, including pricing, is subject to change.
              </p>
              <br />
              <p>
                To support our work, we employ monetization methods like affiliate programs.
                This means that when you click on links to the sites we review and register for
                their services, we might receive a commission. Rest assured, this doesn't cost
                the user extra. We only recommend websites after thorough research and testing.
                Affiliate marketing is a part of our business model. Maintaining our readers'
                trust is paramount, and this is reflected in the quality of recommended
                websites and the information we offer.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SmallDisclosure;
