import React from 'react';

import { Link } from 'react-router-dom';

import Rating from './Rating';
import TrackingLink from './TrackingLink';

import SmallDisclosure from './SmallDisclosure';

import offers from '../data/offers';

import cn from 'classnames';

function TopDating({ names, page = null }) {
  const reviews =
    names.length === 0
      ? offers.slice().sort((a, b) => b.score - a.score)
      : names.reduce((acc, name) => {
          offers.forEach((site) => {
            if (name === site.name) {
              acc.push(site);
            }
          });

          return acc;
        }, []);

  function reviewEvent() {}

  return (
    <React.Fragment>
      <div className="table-top">
        {reviews.map(({ id, name, image, text, score, review, readReview = true }, index) => {
          return (
            <div key={id} className="table-top__tr">
              <div
                className={cn(
                  'popular_choice',
                  page === 'review' ? 'popular_choice_hidden' : ''
                )}>
                <span>№</span>
                {index + 1}
                {index === 0 && <span> Most popular choice</span>}
              </div>
              <TrackingLink offerId={id} className="table-top__td">
                <div className="table-top__logo">
                  <img src={`/images/logos/${image}`} alt={name} />
                  <div className="table-top__score">
                    <span>{score.toFixed(1)}</span>
                  </div>
                </div>
                <Rating
                  rating={score}
                  parentClass="table-top__stars"
                  childrenClass="table-top__stars__item"
                />
              </TrackingLink>
              <div className="list-btns-wrap">
                <div className="table-top__td">
                  <ul className="table-top__plus">
                    {text.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div className="table-top__td">
                  <div className="table-top__score">
                    <TrackingLink offerId={id}>{score.toFixed(1)}</TrackingLink>
                  </div>
                  <div className="table-top__btn-1">
                    <TrackingLink offerId={id} className="btn-0">
                      Visit Site
                    </TrackingLink>
                  </div>
                  {page !== 'review' && readReview && (
                    <div className="table-top__btn-2">
                      <Link to={review} className="link-0" id="review" onClick={reviewEvent}>
                        Read Review
                      </Link>
                    </div>
                  )}
                </div>
              </div>
              <div className="table-top__td">
                <ul className="table-top__plus">
                  {text.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
              <div className="table-top__td">
                <div className="table-top__score">
                  <TrackingLink offerId={id}>{score.toFixed(1)}</TrackingLink>
                </div>
                <div className="table-top__btn-1">
                  <TrackingLink offerId={id} className="btn-0">
                    Visit Site
                  </TrackingLink>
                </div>
                {page !== 'review' && readReview && (
                  <div className="table-top__btn-2">
                    <Link to={review} className="link-0" id="review" onClick={reviewEvent}>
                      Read Review
                    </Link>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {page === 'review' && (
        <>
          <p className="table-sight">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 20 20"
              fill="none">
              <circle cx="10" cy="10" r="10" fill="#333333" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 7.75001L8.847 13.75L8.84599 13.7491V13.75L5 10L6.53799 8.49999L8.84599 10.75L13.462 6.25L15 7.75001Z"
                fill="white"
              />
            </svg>
            Updated for August 2023
          </p>
          <SmallDisclosure />
        </>
      )}
    </React.Fragment>
  );
}

TopDating.defaultProps = {
  names: [],
};

export default TopDating;
