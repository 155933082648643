import React from 'react';

import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';

import Loading from '../components/Loading';

import Header from '../components/Header';
import Footer from '../components/Footer';

import Home from '../pages/home';
import Categories from '../pages/categories';

import './index.scss';

const Reviews = Loadable({
  loader: () => import('../pages/reviews'),
  loading: Loading,
});

const AdvertiserDisclosure = Loadable({
  loader: () => import('../pages/AdvertiserDisclosure'),
  loading: Loading,
});

const NotFound = Loadable({
  loader: () => import('../pages/NotFound'),
  loading: Loading,
});

function getQueryVariable(variable) {
  const vars = window.location.search.substring(1).split('&');

  for (let i = 0; i < vars.length; ++i) {
    const pair = vars[i].split('=');

    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
}

function App() {
  React.useEffect(() => {
    if (localStorage.getItem('tid') === null) {
      localStorage.setItem('tid', getQueryVariable('msclid') || 'organic');
    }
  }, []);

  return (
    <React.Fragment>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />

        <Route exact path="/latinfeels" component={Reviews} />
        <Route exact path="/asianmelodies" component={Reviews} />
        <Route exact path="/amourfactory" component={Reviews} />
        <Route exact path="/lovefort" component={Reviews} />
        <Route exact path="/ukrainiancharm" component={Reviews} />
        <Route exact path="/dateasianwoman" component={Reviews} />
        <Route exact path="/dateasianlady" component={Reviews} />
        <Route exact path="/asiame" component={Reviews} />
        <Route exact path="/asiabeautydate" component={Reviews} />
        <Route exact path="/asianbeautyonline" component={Reviews} />
        <Route exact path="/dateukrainiangirl" component={Reviews} />
        <Route exact path="/cuteasianwoman" component={Reviews} />
        <Route exact path="/colombialady" component={Reviews} />
        <Route exact path="/latinbeautydate" component={Reviews} />
        <Route exact path="/amourfeel" component={Reviews} />
        <Route exact path="/hotukrainians" component={Reviews} />
        <Route exact path="/talkliv" component={Reviews} />
        <Route exact path="/funchatt" component={Reviews} />
        <Route exact path="/placetochat" component={Reviews} />
        <Route exact path="/easternhoneys" component={Reviews} />
        <Route exact path="/orchidromance" component={Reviews} />
        <Route exact path="/theluckydate" component={Reviews} />
        <Route exact path="/theluckydateasia" component={Reviews} />
        <Route exact path="/theluckydatelatam" component={Reviews} />
        <Route exact path="/latamjoy" component={Reviews} />
        <Route exact path="/fortunamor" component={Reviews} />
        <Route exact path="/ladate" component={Reviews} />
        <Route exact path="/chattyspace" component={Reviews} />
        <Route exact path="/chattyspaceasia" component={Reviews} />
        <Route exact path="/chattyspacelatam" component={Reviews} />
        <Route exact path="/romanceast" component={Reviews} />

        <Route exact path="/ukrainian" component={Categories} />
        <Route exact path="/asian" component={Categories} />
        <Route exact path="/latin" component={Categories} />
        <Route exact path="/international" component={Categories} />

        <Route exact path="/advertiser-disclosure" component={AdvertiserDisclosure} />

        <Route component={NotFound} />
      </Switch>
      <Footer />
    </React.Fragment>
  );
}

export default App;
