import React from 'react';

import { Link } from 'react-router-dom';

import SmallDisclosure from '../components/SmallDisclosure';

function Footer() {
  return (
    <div id="footer" className="footer">
      <div className="footer__top">
        <div className="container">
          <ul className="footer__nav">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/international">International</Link>
            </li>
            <li>
              <Link to="/asian">Asian</Link>
            </li>
            <li>
              <Link to="/ukrainian">Ukrainian</Link>
            </li>
            <li>
              <Link to="/latin">Latin</Link>
            </li>
            <li>
              <Link to="/advertiser-disclosure">Advertiser Disclosure</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer__bottom">
        <SmallDisclosure style={{ justifyContent: 'center' }} />
        <div className="container">Copyright 2023 · All rights reserved</div>
      </div>
    </div>
  );
}

export default Footer;
