import React from 'react';

class ErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV !== 'production') {
      console.error({ error, info });
    }
  }

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
