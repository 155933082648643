import React from 'react';

import TrackingLink from './TrackingLink';

const images = [
  {
    id: 'chattyspace',
    name: 'ChattySpace',
    image: 'chatty-space.gif',
  },
];

function Banner() {
  const [imageIndex, setImageIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex((imageIndex) => (imageIndex + 1 === images.length ? 0 : imageIndex + 1));
    }, 800);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const { id, name, image } = images[imageIndex];

  return (
    <div style={{ marginBottom: '40px' }}>
      <TrackingLink offerId={id}>
        <img src={`/images/banner/${image}`} width="100%" alt={name} />
      </TrackingLink>
    </div>
  );
}

export default Banner;
