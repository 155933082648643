import React from 'react';

import Star from './stars/star';
import StarHalf from './stars/star-half';
import StarEmpty from './stars/star-empty';

function isInteger(num) {
  return (num ^ 0) === num;
}

function numRound(num) {
  const d = Math.floor(num);
  const i = num % d;

  return i < 0.25 ? d : i < 0.75 ? d + 0.5 : d + 1;
}

function st(value) {
  const rating = value / 2;
  const MAX_STARS = 5;

  const starsNotSelect = MAX_STARS - numRound(rating);
  const starsSelect = MAX_STARS - starsNotSelect;

  if (isInteger(starsNotSelect) === true) {
    return {
      starsSelect: starsSelect,
      starHalf: 0,
      starsNotSelect: starsNotSelect,
    };
  } else {
    return {
      starsSelect: starsSelect - 0.5,
      starHalf: 1,
      starsNotSelect: starsNotSelect - 0.5,
    };
  }
}

function Rating({ rating, parentClass, childrenClass }) {
  const starsHTML = [];

  const { starsSelect, starHalf, starsNotSelect } = st(rating);

  for (let i = 0; i < starsSelect; ++i) {
    starsHTML.push(
      <div key={`starsSelect-${i}`} className={childrenClass}>
        <Star />
      </div>
    );
  }

  for (let i = 0; i < starHalf; ++i) {
    starsHTML.push(
      <div key={`starHalf-${i}`} className={childrenClass}>
        <StarHalf />
      </div>
    );
  }

  for (let i = 0; i < starsNotSelect; ++i) {
    starsHTML.push(
      <div key={`starsNotSelect-${i}`} className={childrenClass}>
        <StarEmpty />
      </div>
    );
  }

  return <div className={parentClass}>{starsHTML}</div>;
}

export default Rating;
