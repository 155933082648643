import React from 'react';

import SmallDisclosure from '../../components/SmallDisclosure';

import TopDating from '../../components/TopDating';
import SuccessStories from './SuccessStories';

const TITLE = `Best Dating Sites of 2023 | Read on DatingAndMeet`;
const DESCRIPTION = `If you want to build a serious relationship you need to find a good dating website ★ And our full reviews will help you cope with this task ★`;

function Home() {
  React.useEffect(() => {
    document.title = TITLE;
    document.getElementsByTagName('META')['description'].content = DESCRIPTION;
  }, []);

  const topDatingSitesRef = React.useRef();

  const scrollToTopDatingSites = () => {
    const { top } = topDatingSitesRef.current.getBoundingClientRect();

    window.scrollTo({ top, behavior: 'smooth' });
  };

  return (
    <React.Fragment>
      <div className="main-block">
        <div className="container">
          <div className="hero-text">
            <div className="hero-text__tx">
              <h1>LEADING FOREIGN DATING SITES 2023</h1>
            </div>
            <div className="hero-text__btn-bl">
              <button type="button" className="btn-0" onClick={scrollToTopDatingSites}>
                Learn more
              </button>
            </div>
          </div>
        </div>
      </div>

      <div ref={topDatingSitesRef} className="block-3 section">
        <div className="container">
          <div className="title-block">
            <p>
              Through the accumulation and careful analysis of countless site ratings, we have
              meticulously constructed this ranking to serve your convenience!
            </p>
          </div>
          <p className="table-sight">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 20 20"
              fill="none">
              <circle cx="10" cy="10" r="10" fill="#333333" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 7.75001L8.847 13.75L8.84599 13.7491V13.75L5 10L6.53799 8.49999L8.84599 10.75L13.462 6.25L15 7.75001Z"
                fill="white"
              />
            </svg>
            Updated for August 2023
          </p>
          <SmallDisclosure />
          <TopDating
            names={[
              'TheLuckyDate',
              'ChattySpace',
              'Romanceast',
              'LatamJoy',
              'OrchidRomance',
              'LaDate',
            ]}
          />
        </div>
      </div>

      <div className="block-2 section">
        <div className="container">
          <div className="title-block">
            <h3>What are we really for?</h3>
            <p>
              Online dating is becoming more and more popular every year. From a huge number of
              services you need to find some great acquaintances. We’re here to support and
              help you to facilitate your search.
            </p>
          </div>
          <div className="block-2__row">
            <div className="block-2__col">
              <div className="block-2__item">
                <div className="block-2__item__ico">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="star"
                    className="svg-inline--fa fa-star fa-w-18"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512">
                    <path
                      fill="currentColor"
                      d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path>
                  </svg>
                </div>
                <div className="block-2__item__name">Expert evaluation</div>
                <div className="block-2__item__tx">We examined each site back and forth</div>
              </div>
            </div>
            <div className="block-2__col">
              <div className="block-2__item">
                <div className="block-2__item__ico">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="file-alt"
                    className="svg-inline--fa fa-file-alt fa-w-12"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512">
                    <path
                      fill="currentColor"
                      d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"></path>
                  </svg>
                </div>
                <div className="block-2__item__name">Trusted scores</div>
                <div className="block-2__item__tx">
                  More than 5 factors affect the site score
                </div>
              </div>
            </div>
            <div className="block-2__col">
              <div className="block-2__item">
                <div className="block-2__item__ico">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="heart"
                    className="svg-inline--fa fa-heart fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512">
                    <path
                      fill="currentColor"
                      d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path>
                  </svg>
                </div>
                <div className="block-2__item__name">Custom rating</div>
                <div className="block-2__item__tx">
                  We look for comments of real users and provide them here
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SuccessStories />
    </React.Fragment>
  );
}

export default Home;
